import React from "react";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.css";
import Jumbotron from "react-bootstrap/Jumbotron";
import Image from "react-bootstrap/Image";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { password: null };

    this.handleClick = this.handleClick.bind(this);
  }

  generatePassword() {
    var length = 8,
      charset = "abcdefghijklnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      password = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      password += charset.charAt(Math.random() * n);
    }
    return password;
  }

  handleClick() {
    this.setState((state) => ({
      password: this.generatePassword(),
    }));
  }

  render() {
    return (
      <div className="App">
        <div className="bluegradient">
          <Jumbotron fluid bsPrefix="jumbotron header">
            <Container>
              <header>
                <h3 className="logo">Salasanageneraattori.fi</h3>

                <Col md={{ span: 6, offset: 3 }}>
                  <h1 className="taglineh1">
                    Luo turvallinen salasana helposti
                  </h1>
                  <Col md={{ offset: 8 }}>
                    <Image src="img/kokeile.png" alt="kokeile" />
                  </Col>
                </Col>
              </header>
            </Container>
          </Jumbotron>
        </div>

        <Jumbotron fluid bsPrefix="jumbotron passwordbar">
          <Container>
            <Col md={{ span: 8, offset: 2 }}>
              <InputGroup>
                <FormControl
                  aria-label="salasanatext"
                  value={this.state.password || 'Paina "luo salasana"'}
                  readOnly
                  bsPrefix="form-control passwordform"
                />
                <InputGroup.Append>
                  <Button
                    type="button"
                    variant="success"
                    onClick={this.handleClick}
                    // onClick="salasana();_gaq.push(['_trackEvent', 'Password', 'Generate', '8char-az-AZ-09']);"
                  >
                    Luo Salasana
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Col>
          </Container>
        </Jumbotron>

        <Container>
          <Row>
            <Col md={6}>
              <Col md={{ offset: 4 }}>
                <Image
                  src="img/padlock.svg"
                  alt="lukko"
                  roundedCircle
                  className="iconsize"
                ></Image>
              </Col>

              <p>
                Hyvä salasana on tärkeä turvallisuuden kannalta, siksi tarjoamme
                sinulle salasanageneraattorin, joka luo sinulle sellaisen. On
                suositeltavaa, että salasanasi on ainakin 8 merkkiä pitkä,
                sisältäen numeroita, isoja kirjaimia ja pieniä kirjaimia. Jos
                haluat lisäturvaa, voit generoida salasanan kahdesti ja liittää
                ne yhteen. Täten saat 16 merkkisen salasanan. (Muista, että
                kaikki nettisivut/applikaatiot eivät välttämättä tue 16
                merkkisiä salasanoja)
              </p>
            </Col>

            <Col md={6}>
              <Col md={{ offset: 4 }}>
                <Image
                  src="img/checkbox.svg"
                  alt="lukko"
                  roundedCircle
                  className="iconsize"
                ></Image>
              </Col>
              <p>
                Kun haluat pitää nettisivusi tai tunnuksesi turvassa, käväise
                salasanageneraattori.fi ja turvaa tietosi. Helppokäyttöinen
                generaattorimme on hyvä apu esimerkiksi tietokantojen tai
                sisällönhallintajärjestelmien turvaamisessa. Muista tallentaa
                sivumme kirjanmerkkeihin ja vierailla kun olet turvan tarpeessa.
                Kehitämme jatkuvasti salasanageneraattoria lisäämällä
                toiminnallisuuksia ja tarjoamalla kiinnostavaa tietoa.
              </p>
            </Col>
          </Row>
        </Container>
        <footer className="footer">
          <Container>
            <Navbar fixed="bottom">
              <Navbar.Brand>
                <a href="http://www.salasanageneraattori.fi">
                  &copy; 2021 Salasanageneraattori.fi
                </a>
              </Navbar.Brand>
            </Navbar>
          </Container>
        </footer>
      </div>
    );
  }
}

export default App;
